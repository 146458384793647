import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { vipPlanUpdate, addVipPlan } from "../../store/slice/appSlice";
import Input from "../extra/Input";
import ToggleSwitch from "../extra/ToggleSwitch";
import Button from "../extra/Button";
import ReactSelect from "react-select";
import Textarea from "../extra/TextArea";
import { useLocation } from "react-router-dom";
import { baseURL } from "../utils/config";

const VipPlanDialogue = (props) => {
  const dispatch = useDispatch();
  const selectTimeData = ["Second", "Minute", "Hour", "Day", "Month", "Year","Life Time"];
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [planName, setPlanName] = useState("");
  const [validity, setValidity] = useState("");
  const [validityType, setValidityType] = useState("");
  const [coin, setCoin] = useState("");
  const [dollar, setDollar] = useState("");
  const [rupee, setRupee] = useState("");
  const [discount, setDiscount] = useState("");
  const [productKey, setProductKey] = useState("");
  const [note, setNote] = useState("");
  const [description, setDescription] = useState("");
  const [other, setOther] = useState("");

  const [descriptionDollar,setDescriptionDollar] = useState("");
  const [discountDollar, setDiscountDollar] = useState(0);

  const [logoImage, setLogoImage] = useState("");
  const [logoImageShow, setLogoImageShow] = useState();
  const location = useLocation();
  const [error, setError] = useState({
    planName: "",
    validity: "",
    validityType: "",
    coin: "",
    rupee: "",
    dollar: "",
    discount: "",
    productKey: "",
    note: "",
    logoImage: "",
    selectedDeveloper: "",
    discountDollar:"",
    descriptionDollar:""
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleFileUpload = (e) => {
    setLogoImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setLogoImageShow(showLogo);
    if (!e.target.files[0]) {
      return setError({
        ...error,
        logoImage: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        logoImage: "",
      });
    }
  };

  useEffect(() => {
    if (dialogueData) {
      setPlanName(dialogueData?.name);
      setValidity(dialogueData?.validity);
      setValidityType(dialogueData?.validityType);
      setCoin(dialogueData?.coin);
      setDollar(dialogueData?.dollar);
      setRupee(dialogueData?.rupee);
      setDiscount(dialogueData?.discount);
      setProductKey(dialogueData?.productKey);
      setNote(dialogueData?.note);
      setLogoImage(baseURL + "/" + dialogueData?.image);
      setDescription(dialogueData?.description);
      setLogoImageShow(baseURL + "/" + dialogueData?.image);
      setOther(dialogueData?.other);
      setDescriptionDollar(dialogueData?.descriptionDollar);
      setDiscountDollar(dialogueData?.discountDollar);
    }
  }, [dialogueData]);

  const handleSubmit = (e) => {
    if (
      (!planName,
      !validity,
      !validityType,
      !coin,
      !dollar,
      !rupee,
      !discount,
      !productKey,
      !discountDollar,
      !descriptionDollar,
      !logoImageShow)
    ) {
      let error = {};
      if (!planName) error.planName = "Name Is Required !";
      if (!logoImage) error.logoImage = "Image  Is Required !";
      if (!validity) error.validity = "Validity  Is Required !";
      if (!validityType) error.validityType = "Validity Type  Is Required !";
      if (!coin) error.coin = "Coin Is Required !";
      if (!dollar) error.dollar = "Dollar Is Required !";
      if (!rupee) error.rupee = "Rupee Is Required !";
      if (!discount) error.discount = "Discount Is Required !";
      if (!productKey) error.productKey = "Product Key Is Required !";
      if(!discountDollar) error.discountDollar = "Discount Dollar Is Required !";
      if(!descriptionDollar || descriptionDollar === "") error.descriptionDollar = "Description Dollar Is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", planName);
      formData.append("validity", validity);
      formData.append("validityType", validityType);
      formData.append("coin", coin);
      formData.append("dollar", dollar);
      formData.append("rupee", rupee);
      formData.append("discount", discount);
      formData.append("productKey", productKey);
      formData.append("image", logoImage);
      formData.append("description", description);
      formData.append("descriptionDollar", descriptionDollar);
      formData.append("discountDollar", discountDollar);
      formData.append("other", other);
      debugger
      formData.append("note", note.trim() ? note.trim() : "");

      if (dialogueData) {
        formData.append("planId", dialogueData?._id);
        const payload = {
          appId: location?.state?.editAppData?._id,
          planId: dialogueData?._id,
          data: formData,
        };
        dispatch(vipPlanUpdate(payload));
        dispatch(closeDialog());
      } else {
        const payload = {
          appId: location?.state?.editAppData?._id,
          data: formData,
        };
        dispatch(addVipPlan(payload));
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="vipPlan-edit">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update VipPlan" :"Create New VipPlan"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={`Name`}
                    id={`planName`}
                    type={`text`}
                    value={planName}
                    errorMessage={error.planName && error.planName}
                    onChange={(e) => {
                      setPlanName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          planName: `Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          planName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Validity`}
                    id={`validity`}
                    type={`number`}
                    value={validity}
                    errorMessage={error.validity && error.validity}
                    onChange={(e) => {
                      setValidity(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          validity: `Validity Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          validity: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 select-time-selector">
                  <h6>Select Validity Type</h6>
                  <select
                    value={validityType}
                    name="selectValidityType"
                    onChange={(e) => {
                      setValidityType(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          validityType: `Validity Type Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          validityType: "",
                        });
                      }
                    }}
                  >
                    {selectTimeData?.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                  {error?.validityType && (
                    <p className="errorMessage">{error?.validityType}</p>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Coin`}
                    id={`coin`}
                    type={`number`}
                    value={coin}
                    errorMessage={error.coin && error.coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          coin: `Coin Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          coin: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Dollar`}
                    id={`dollar`}
                    type={`number`}
                    value={dollar}
                    errorMessage={error.dollar && error.dollar}
                    onChange={(e) => {
                      setDollar(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          dollar: `Dollar Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          dollar: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Rupee`}
                    id={`rupee`}
                    type={`number`}
                    value={rupee}
                    errorMessage={error.rupee && error.rupee}
                    onChange={(e) => {
                      setRupee(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          rupee: `Rupee Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          rupee: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Discount`}
                    id={`discount`}
                    type={`number`}
                    value={discount}
                    errorMessage={error.discount && error.discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          discount: `Discount Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          discount: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Discount Dollar`}
                    id={`discountDollar`}
                    type={`number`}
                    value={discountDollar}
                    errorMessage={error.discountDollar && error.discountDollar}
                    onChange={(e) => {
                      setDiscountDollar(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          discountDollar: `DiscountDollar Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          discountDollar: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Description`}
                    id={`description`}
                    type={`text`}
                    value={description}
                    errorMessage={error.description && error.description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          description: `description Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          description: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Description Dollar`}
                    id={`descriptionDollar`}
                    type={`text`}
                    value={descriptionDollar}
                    errorMessage={error.descriptionDollar && error.descriptionDollar}
                    onChange={(e) => {
                      setDescriptionDollar(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          descriptionDollar: `descriptionDollar Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          descriptionDollar: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Product Key`}
                    id={`productKey`}
                    type={`text`}
                    value={productKey}
                    errorMessage={error.productKey && error.productKey}
                    onChange={(e) => {
                      setProductKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          productKey: `Product Key Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          productKey: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 ">
                  <Textarea
                    label={`Note`}
                    placeholder={"Enter Note..."}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    row={2}
                    value={note}
                    name={"note"}
                  />
                </div>
                <div className="col-12 ">
                  <Textarea
                    label={`Other details`}
                    placeholder={"Other details..."}
                    onChange={(e) => {
                      setOther(e.target.value);
                    }}
                    row={2}
                    value={other}
                    name={"other"}
                  />
                </div>
                <div className="col-12 col-md-6 image-input mt-2">
                  <label className="label-input">App Logo</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className="form-input"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={handleFileUpload}
                  />
                  <p className="errorMessage">{error.logoImage}</p>
                </div>
                <div className="col-12 col-md-6 imgShow">
                  {logoImageShow && <img src={logoImageShow} />}
                </div>
              </div>
            </div>
            <div className="row dialog-footer">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { vipPlanUpdate, addVipPlan })(VipPlanDialogue);

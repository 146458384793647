// export const baseURL = "http://localhost:6007/";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "http://192.168.29.19:5000";
// export const baseURL = "https://virtualdesk.codderlab.com";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "https://app.hashcrafters.com/";
// export const baseURL = "https://rayzi.codderlab.com";
export const baseURL = "https://testvirtualdesk.codderlab.com";
export const key = "vHlwi52J3p6Qq8gcxVHZJXEvMqrcaVwF";
    